body {
  margin: 0;
}

a {
  cursor: pointer;
}

.nomargin {
  margin: 0 !important;
  cursor: default;
}
